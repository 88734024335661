import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  useMutation,
  useRedirect,
  useNotify,
  required,
  useDataProvider, TopToolbar, usePermissions, EditButton
} from "react-admin";
import { useShowController } from 'react-admin';
import { Button } from "@material-ui/core";
import EvStationIcon from "@material-ui/icons/EvStation";
import { NumberInput } from "ra-ui-materialui";
import Toolbar from "../components/EditToolbar";
import ShowToolbar from "../components/ShowToolbar";
import Title from "../components/Title";
import { DateTimeInput } from "../components/DateTimeInput";
import { EditWithMutation } from "../utils/helpers";
import useWindowSize from "../utils/useWindowSize";
import { FormStyles } from "../components/Formstyles";
import { Typography } from "@material-ui/core";
import { usePreferences } from "@react-admin/ra-preferences";

const CewlEdit=(props) => {
  const [data, setData]=useState();
  const [newData, setNewData]=useState();
  const dataProvider=useDataProvider();
  const [mutate]=useMutation();
  const notify=useNotify();
  const redirect=useRedirect();
  const classes=FormStyles()();
  const ref=useRef();
  const {resource, id}=props;
  const [userRole, setUserRole]=useState();
  const {permissions}=usePermissions();
  const [width]=useWindowSize();
  const [preferences]=usePreferences("theme");
  let windowWidth=width > 415 ? "100%" : "258px";

  const {record} = useShowController(props);
  useEffect(() => {
    permissions && permissions.rls && setUserRole(permissions.rls);
  }, [permissions]);

  useEffect(() => {
    setData(newData);
  }, [newData, data]);

  const getCveData=() => {
    id &&
    dataProvider
      .getOne(`cewl/nvd`, {id: id})
      .then(({data}) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const save=useCallback(
    async(values) => {
      return await EditWithMutation(values, mutate, notify, redirect, resource);
    },
    [mutate, notify, redirect, resource]
  );

  const onChange=(e, value) => {
    if(data) {
      const newData=data;
      newData[value]=e.target.value;
      setNewData(newData);
    }
  };

  const PostEditActions=({basePath, data}) => (
    <TopToolbar >
      <EditButton basePath = { basePath } record = { data } />
    </TopToolbar >
);

  return (
    <Edit undoable = { false } title = { <Title /> } { ...props }
          actions = { userRole === "super_admin" ? <PostEditActions /> : null } >
      <SimpleForm toolbar = { userRole === "super_admin" ? <Toolbar /> : <ShowToolbar /> }
                  className = { classes.wrapper } save = { save } >
        <div className = { classes.left__block } >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              <TextInput
                label = "CVE"
                allowEmpty
                source = "id"
                variant = "outlined"
                className = { classes.item }
              />
              { userRole === "super_admin" ?
                <Button
                  variant = "contained"
                  startIcon = { <EvStationIcon /> }
                  color = "secondary"
                  onClick = { () => getCveData() }
                  className = { classes.button }
                  ref = { ref }
                  disabled = { userRole !== "super_admin" }
                >
                  Fill with NVD Details
                </Button >
                :
                <DateTimeInput
                  label = "Notification Date"
                  allowEmpty
                  source = "notification_date"
                  className = { classes.date }
                  defaultValue = { data && data.notification_date && data.notification_date }
                  fullWidth = { true }
                />
              }
            </div >
            <div className = { classes.block__item } >
              <TextInput
                onChange = { (e) => onChange(e, "vendor") }
                label = "Vendor"
                source = "vendor"
                variant = "outlined"
                className = { classes.item }
                validate = { [required()] }
                defaultValue = { data && data.vendor && data.vendor }
              />
              <TextInput
                onChange = { (e) => onChange(e, "product") }
                label = "Product"
                source = "product"
                variant = "outlined"
                className = { classes.item }
                validate = { [required()] }
                defaultValue = { data && data.product && data.product }
              />
            </div >
          </div >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
            <DateTimeInput
                label = "CVE Date"
                allowEmpty
                source = "cve_date"
                className = { classes.date }
                defaultValue = { data && data.cve_date && data.cve_date }
                fullWidth = { true }
              />
            <DateTimeInput
                label = "Date Added"
                allowEmpty
                source = "date_added"
                className = { classes.date }
                defaultValue = { data && data.date_added && data.date_added }
                fullWidth = { true }
              />
        </div >
      <div className = { classes.block__item } >
            <NumberInput
                onChange = { (e) => onChange(e, "year") }
                label = "Year"
                allowEmpty
                source = "year"
                variant = "outlined"
                className = { classes.item }
                defaultValue = { data && data.year && data.year }
              />         
              <TextInput
                onChange = { (e) => onChange(e, "cwe") }
                label = "CWE"
                allowEmpty
                source = "cwe"
                variant = "outlined"
                className = { classes.item }
                defaultValue = { data && data.cwe && data.cwe }
              />
            </div >
          </div >
          <div className = { `${ classes.block }  ${ classes.baseline } ` } >
            <div className = { `${ classes.new_block__item } ${ classes.baseline }` } >
                <TextInput
                onChange = { (e) => onChange(e, "external_note") }
                label = "External Note"
                allowEmpty
                multiline
                source = "external_note"
                variant = "outlined"
                className={classes.link}
                defaultValue = { data && data.external_note && data.external_note }
                />
               <TextInput
                onChange = { (e) => onChange(e, "internal_note") }
                label = "Internal Note"
                allowEmpty
                multiline
                className={classes.item}
                source = "internal_note"
                variant = "outlined"
                defaultValue = { data && data.internal_note && data.internal_note }
                />  
        </div>     
      <div className = { `${ classes.new_block__item } ${ classes.baseline }` } >
           <TextInput
                onChange = { (e) => onChange(e, "cve_reference") }
                label = "Reference Link"
                allowEmpty  
                source = "cve_reference"
                variant = "outlined"
                className = { classes.link }
                defaultValue = { data && data.cve_reference && data.cve_reference }
              />
           <TextInput
                onChange = { (e) => onChange(e, "vulnerability_type") }
                label = "Vulnerability Type"
                allowEmpty
                source = "vulnerability_type"
                variant = "outlined"
                className = { classes.item }
                defaultValue = {
                data && data.vulnerability_type && data.vulnerability_type
                }
              />
            </div >

         </div>
          <div className = { `${ classes.block__item } ${ classes.baseline }` } >
              <TextInput
                onChange = { (e) => onChange(e, "description") }
                label = "Description"
                source = "description"
                multiline
                style = { {width: `${ windowWidth }`} }
                variant = "outlined"
                className = { classes.link }
                defaultValue = { data && data.description && data.description }
              />
            </div >
            <div className = { classes.label__wrapper } >
                  <Typography className = { classes.label }
                              component = "h2"
                              style = { {
                                borderBottom: preferences === "dark" ? "2px solid rgba(81, 81, 81, 1)" : "2px solid rgba(224, 224, 224, 1)",
                              } }
                  >CTCI-Intel
                  </Typography >
                </div >
            <div className = { classes.block } >
            <div className = { classes.block__item } >
              <DateTimeInput
                label = "Honeypot"
                allowEmpty
                source = "honeypot"
                helperText = "in GMT Time"
                className = { classes.date }
                defaultValue = { data && data.honeypot && data.honeypot }
                fullWidth = { true }
              />
              <DateTimeInput
                label = "Intel"
                allowEmpty
                source = "intel"
                helperText = "in PST Time"
                className = { classes.date }
                defaultValue = { data && data.intel && data.intel }
                fullWidth = { true }
              />
            </div >
            <div className = { classes.block__item } >
            <DateTimeInput
                label = "Research"
                allowEmpty
                source = "research"
                helperText = "in PST Time"
                className = { classes.date }
                defaultValue = { data && data.research && data.research }
                fullWidth = { true }
              />
            </div >
          </div >
          <div className = { classes.label__wrapper } >
                  <div className={classes.label} style = { {
                      borderBottom: preferences === "dark" ? "2px solid rgba(81, 81, 81, 1)" : "2px solid rgba(224, 224, 224, 1)",
                              } }
                  > 
                  </div >
                </div >
             <div className = { `${ classes.block } ${ classes.baseline }` } >
            <div className = { classes.block__item } >
              {userRole !== "super_admin" && record && record.notified && record.notified
              || userRole === "super_admin" ?
              <>
                <BooleanInput
                  label = "notified "
                  defaultValue = { data && data.notified && data.notified }
                  source = "notified"
                  className = { classes.item }
                />
                <DateTimeInput
                  label = "Notification Date"
                  allowEmpty
                  source = "notification_date"
                  className = { classes.date }
                  defaultValue = { data && data.notification_date && data.notification_date }
                  fullWidth = { true }
                />
              </>:null}
            </div >
            <div className = { classes.block__item } >
              {userRole!=="super_admin" && record && record.enabled && record.enabled || userRole==="super_admin" ?
                <>
                  <BooleanInput
                    label = "Enabled"
                    defaultValue = { data && data.enabled && data.enabled }
                    source = "enabled"
                    className = { classes.item }
                  />              
                  <DateTimeInput
                    label = "Enable from Date"
                    allowEmpty
                    source = "enable_from_date"
                    className = { classes.date }
                    defaultValue = {
                    data && data.enable_from_date && data.enable_from_date
                    }
                    fullWidth = { true }
                  />
                </>:null}
            </div >
          </div >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              {userRole!=="super_admin" && record && record.removed && record.removed 
                || userRole==="super_admin"?  
                <>
                  <BooleanInput
                    label = "Removed"
                    defaultValue = { data && data.removed && data.removed }
                    source = "removed"
                    className = { classes.item }
                  />
                 <DateTimeInput
                    label = "Removed Date"
                    allowEmpty
                    source = "removed_date"
                    className = { classes.date }
                    defaultValue = { data && data.removed_date && data.removed_date }
                    fullWidth = { true }
                  />              
                </>:null}            
              <div className = { classes.block__item } >
               <NumberInput
                onChange = { (e) => onChange(e, "base_score") }
                label = "CVE Score"
                allowEmpty
                source = "base_score"
                variant = "outlined"
                className = { classes.item }
                defaultValue = { data && data.base_score && data.base_score }
                />
                <div >
                  <AutocompleteInput
                    label = "CVE Severity"
                    source = "base_severity"
                    emptyText = "UNKNOWN"
                    choices = { [
                      {id: "UNKNOWN", name: "Unknown"},
                      {id: "LOW", name: "Low"},
                      {id: "MEDIUM", name: "Medium"},
                      {id: "HIGH", name: "High"},
                      {id: "CRITICAL", name: "Critical"},
                      {id: "NUCLEAR", name: "Nuclear"},
                    ] }
                    variant = "outlined"
                    className = { classes.item }
                  />
              </div >
           </div>           
            </div >
          </div >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              <BooleanInput
               label = "CVSSv2"
               source = "base_metric_v2"
               className = { classes.item }
               defaultValue = { data && data.base_metric_v2 && data.base_metric_v2 }
              />              
              <BooleanInput
                label = "Special"
                defaultValue = { data && data.special && data.special }
                source = "special"
                className = { classes.item }
              />
            </div >
            <div  className = { classes.block__item }>
              <BooleanInput
              label = "CVSSv3"
              source = "base_metric_v3"
              className = { classes.item }
             defaultValue = { data && data.base_metric_v3 && data.base_metric_v3 }
              />              
            </div>
           </div >
        </div >
        <div className = { classes.right__block } >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              <NumberInput
                label = "Id Ref"
                allowEmpty
                source = "id_ref"
                disabled
                variant = "outlined"
                className = { classes.item }
              />
            </div >
            <div className = { classes.custom__box } ></div >
          </div >
        </div >
      </SimpleForm >
    </Edit >
  );
};

export default CewlEdit;
