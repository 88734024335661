import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./styles.css";
import LinkIcon from "@material-ui/icons/Link";

export default forwardRef((props, ref) => {

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ["custom-tooltip"];
      },
    };
  });

  return (
    <div className="custom-tooltip" >
      <a href={props.value} target="_blank" rel="noreferrer">
        {props.value} <LinkIcon fontSize="small" />
      </a>
    </div>
  );
});
