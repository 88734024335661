// A way to handle global variables
// https://stackoverflow.com/questions/34351804/how-to-declare-a-global-variable-in-react
import dotenv from 'dotenv';
dotenv.config();

const location_origin = window.location.origin
const http_protocol = window.location.protocol
const current_hostname = window.location.hostname;
const api_runs_on_port = "5000"
const production_api_host = "https://api.ctci.ai/"

const local_host_myEndpoints = {
  REACT_APP_CRUD_ADMIN_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1",
  REACT_APP_CRUD_CEWL_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/cewl?q=",
  REACT_APP_CRUD_LEGIT_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port +"/api/v1/ip/",
  REACT_APP_CRUD_ADMIN_LOGIN_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/login",
  REACT_APP_CRUD_ADMIN_REGISTER_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/register",
  REACT_APP_CRUD_ADMIN_CHANGE_PASSWORD_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "api/v1/change_password",
  REACT_APP_CRUD_ADMIN_FORGOT_PASSWORD_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/forgot_password",
  REACT_APP_DOCUMENTATION_URL: "https://ctci.atlassian.net/wiki/spaces/CTCIDOC/overview",
  REACT_APP_ADDRESS_CONFIRMATION_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/resend_confirmation_web",
  REACT_APP_TEST_NOTIFICATION_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/test/notification_delivery",
  REACT_APP_CHECK_AND_NOTIFY_ENDPOINT: http_protocol + "//" + current_hostname + ":" + api_runs_on_port + "/api/v1/notification/check_and_notify"
}

const test_myEndpoints = {
    REACT_APP_CRUD_ADMIN_ENDPOINT: "https://test-api.ctci.ai/api/v1",
    REACT_APP_CRUD_CEWL_ENDPOINT: "https://test-api.ctci.ai/api/v1/cewl?q=",
    REACT_APP_CRUD_LEGIT_ENDPOINT: "https://test-api.ctci.ai/api/v1/ip/",
    REACT_APP_CRUD_ADMIN_LOGIN_ENDPOINT: "https://test-api.ctci.ai/api/v1/login",
    REACT_APP_CRUD_ADMIN_REGISTER_ENDPOINT: "https://test-api.ctci.ai/api/v1/register",
    REACT_APP_CRUD_ADMIN_CHANGE_PASSWORD_ENDPOINT: "https://test-api.ctci.ai/api/v1/change_password",
    REACT_APP_CRUD_ADMIN_FORGOT_PASSWORD_ENDPOINT: "https://test-api.ctci.ai/api/v1/forgot_password",
    REACT_APP_DOCUMENTATION_URL: "https://ctci.atlassian.net/wiki/spaces/CTCIDOC/overview",
    REACT_APP_ADDRESS_CONFIRMATION_ENDPOINT: "https://test-api.ctci.ai/api/v1/resend_confirmation_web",
    REACT_APP_TEST_NOTIFICATION_ENDPOINT: "https://test-api.ctci.ai/api/v1/test/notification_delivery",
    REACT_APP_CHECK_AND_NOTIFY_ENDPOINT: "https://test-api.ctci.ai/api/v1/notification/check_and_notify",
    REACT_APP_NOTIFICATION_ENDPOINT: "https://test-api.ctci.ai/api/v1/notification"
}

const production_myEndpoints = {
    REACT_APP_CRUD_ADMIN_ENDPOINT: production_api_host + "/api/v1",
    REACT_APP_CRUD_CEWL_ENDPOINT: production_api_host + "/api/v1/cewl?q=",
    REACT_APP_CRUD_LEGIT_ENDPOINT: production_api_host + "/api/v1/ip/",
    REACT_APP_CRUD_ADMIN_LOGIN_ENDPOINT: production_api_host + "/api/v1/login",
    REACT_APP_CRUD_ADMIN_REGISTER_ENDPOINT: production_api_host + "/api/v1/register",
    REACT_APP_CRUD_ADMIN_CHANGE_PASSWORD_ENDPOINT: production_api_host + "api/v1/change_password",
    REACT_APP_CRUD_ADMIN_FORGOT_PASSWORD_ENDPOINT: production_api_host + "/api/v1/forgot_password",
    REACT_APP_DOCUMENTATION_URL: "https://ctci.atlassian.net/wiki/spaces/CTCIDOC/overview",
    REACT_APP_ADDRESS_CONFIRMATION_ENDPOINT: production_api_host + "/api/v1/resend_confirmation_web",
    REACT_APP_TEST_NOTIFICATION_ENDPOINT: production_api_host + "/api/v1/test/notification_delivery",
    REACT_APP_CHECK_AND_NOTIFY_ENDPOINT: production_api_host + "/api/v1/notification/check_and_notify"
}

const myEndpoints = () => {
  // for some hardcoded end points for uat being test
    if (current_hostname.startsWith("uat")){
        return test_myEndpoints
    }
    else if (process.env.NODE_ENV === "development"){
        return test_myEndpoints
    }
    else if (process.env.NODE_ENV === "test"){
        return test_myEndpoints
    }
    else if (process.env.NODE_ENV === "production"){
        return production_myEndpoints
    }

}

console.log("The environment it is running in is:")
console.log(process.env.NODE_ENV);
console.log(myEndpoints().REACT_APP_CRUD_CEWL_ENDPOINT)

export default myEndpoints
