import { makeStyles } from "@material-ui/core/styles";
import { usePreferences } from "@react-admin/ra-preferences";
import useWindowSize from "../utils/useWindowSize";

export const FormStyles=() => {
  const [preferences]=usePreferences("theme");
  const [windowWidth]=useWindowSize();

  return makeStyles({
    wrapper: {
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }
    },
    show: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    left__block: {
      width: "fit-content",
    },
    right__block: {
      display: "flex",
      flexWrap: "wrap",
      height: "fit-content",
      width: windowWidth < 1684 ? "100%" : "270px",
      justifyContent: windowWidth < 820 ? "center" : "null",
      alignItems: windowWidth < 820 ? "center" : "null",
    },
    big_block: {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "1080px",

    },
    block: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      width: "fit-content",
      justifyContent: windowWidth < 820 ? "center" : "null",
      maxWidth: "1080px",
    },
    label__wrapper: {
      display: "flex",
      width: "100%",
      fontWeight:"bold"
      },
    label: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingBottom: "10px",
      marginBottom:"10px"
    },  
    block__item: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: windowWidth < 820 ? "center" : "null",
    },
    new_block__item: {
      display: "flex",
      flexWrap:  windowWidth < 1380 ? "wrap" : "nowrap",
      flexGrow:2,
      alignItems: "center",
      justifyContent: windowWidth < 820 ? "center" : "null",
    },
    item: {
      width: "255px",
      minWidth: "255px",
      margin: "8px 15px 4px 0",
      height: "64px",
    },
    item__number: {
      border: "1px solid rgba(0, 0, 0, 0.3)",
      borderColor: preferences === "dark" ? "#767676" : "rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      boxSizing: "border-box",
      width: "255px",
      padding: "9px 0px 9px 14px",
      margin: "-16px 15px 4px 0",
      "&:hover": {
        borderColor: preferences === "dark" ? "#fff" : "#000",
      },
    },
    multiline: {
      width: "255px",
      minWidth: "255px",
      margin: "8px 15px 4px 0",
      "& > div > textarea" : {
        overflow: "auto !important",
        maxHeight: "100px",
      }
    },
    country_picker: {
      padding: "0 5px",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      borderColor: preferences === "dark" ? "#767676" : "rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      width: "244px",
      height: "38px",
      "&:hover": {
        borderColor: preferences === "dark" ? "#fff" : "#000",
      },
      "& > div > div": {
        // cursor: "text",
        paddingLeft: "5px",
      },
      "&:before": {
        content: "none !important",
      },
      "&:after": {
        content: "none !important",
      },
      "& > div > div:focus": {
        background: "none",
      },
    },
    bool: {
      height: "64px",
      width: "255px",
      margin: "8px 15px 4px 0",
    },
    switch: {
      paddingLeft: "11px",
      marginRight: "0",
    },

    empty: {
      width: "255px",
      margin: "8px 15px 4px 0",
      height: "64px",
    },
    empty_p: {
      empty: {
        width: "100%",
        height: "10px",
      },
    },
    custom__box: {
      width: "255px",
      minWidth: "255px",
      height: "64px",
      margin: "8px 15px 4px 0",
    },
    copy__button: {
      "& > button" :{
        padding: "0"
      }
    },
    role_picker: {
      width: "255px",
      minWidth: "255px",
      height: "64px",
      margin: "8px 15px 4px 0",
      "& > div": {
        margin: "0"
      }
    },
    legend: {
      background: preferences === "dark" ? "#424242" : "#fff",
      color: preferences === "dark" ? "#c6c6c6" : "#757575",
      transform: "translate(3px, -10px) scale(0.75)",
      position: "absolute",
      zIndex: 1,
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "1rem",
      fontFamily: "Roboto",
    },
    date: {
      width: "100%",
      minWidth: "100%",
      "& > div": {
        margin: "0",
        width: "100%",
      },
      "& > div > div > input": {
        height: "3px",
        width: "185px",
      },
      "& > div > label": {
        transform: "translate(12px, 13px) scale(1)",
      },
      "&:first-child": {
        // marginRight: "15px",
      },
    },
    link: {
      width: windowWidth > 638 ? "100%" : "255px",
      minWidth: windowWidth > 638 ? "525px" : "255px",
      margin: "8px 15px 4px 0",
      textDecoration: "none",
      "& > div > textarea" : {
        overflow: "auto !important",
        maxHeight: "100px",
      }
    },
    button: {
      alignSelf: "baseline",
      margin: "8px 15px 4px 0",
      height: "40px",
      width: "255px",
      minWidth: "255px",
      backgroundColor: "#2196f3",
      color: "white",
    },
    button__password: {
      display: "flex",
      boxSizing: "border-box",
      width: "100%",
      color: "#2196f3",
      border: "1px solid #2196f3",
      borderRadius: "4px",
      height: "40px",
      cursor: "pointer",
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover": {
        backgroundColor: "#2196f3",
        color: "#fff",
      }
    },
    baseline: {
      alignItems: "flex-start"
    },
  });

}