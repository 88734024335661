import React, { useCallback, useEffect, useState } from "react";
import {
  TextInput,
  BooleanInput,
  SimpleForm,
  Create,
  useDataProvider,
  AutocompleteInput,
  useMutation,
  useRedirect,
  useNotify,
  required, usePermissions,
} from "react-admin";
import { NumberInput } from "ra-ui-materialui";
import Button from "@material-ui/core/Button";
import EvStationIcon from "@material-ui/icons/EvStation";
import Toolbar from "../components/EditToolbar";
import Title from "../components/Title";
import { DateTimeInput } from "../components/DateTimeInput";
import { CreateWithMutation } from "../utils/helpers";
import { FormStyles } from "../components/Formstyles";
import useWindowSize from "../utils/useWindowSize";
import { Typography } from "@material-ui/core";
import { usePreferences } from "@react-admin/ra-preferences";

const CewlCreate=(props) => {
  const [data, setData]=useState();
  const [cve, setCve]=useState();
  const [error, setError]=useState();
  const dataProvider=useDataProvider();
  const [mutate]=useMutation();
  const notify=useNotify();
  const redirect=useRedirect();
  const classes=FormStyles()();
  const {resource}=props;
  const [userRole, setUserRole]=useState();
  const {permissions}=usePermissions();

  const [preferences]=usePreferences("theme");
  const [width]=useWindowSize();
  let windowWidth=width > 415 ? "100%" : "258px";

  useEffect(() => {
    permissions && permissions.rls && setUserRole(permissions.rls);
  }, [permissions]);

  const getCveData=() => {
    cve &&
    dataProvider
      .getOne(`cewl/nvd`, {id: cve})
      .then(({data}) => {
        setData(data);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setData(" ");
      });
  };

  const save=useCallback(
    async(values) => {
      return await CreateWithMutation(
        values,
        mutate,
        notify,
        redirect,
        resource
      );
    },
    [mutate, notify, redirect, resource]
  );

  return (
    <Create undoable = "false" { ...props } title = { <Title value = "Create CEWL" /> } >
      <SimpleForm
        toolbar = { <Toolbar /> }
        defaultValue = { data }
        className = { classes.wrapper }
        save = { save }
      >
        <div className = { classes.left__block } >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              <TextInput
                label = "CVE"
                allowEmpty
                source = "id"
                variant = "outlined"
                className = { classes.item }
                onChange = { (e) => setCve(e.target.value) }
                helperText = { error && error }
                validate = { [required()] }
              />
              { userRole === "super_admin" ?
                <Button
                  variant = "contained"
                  startIcon = { <EvStationIcon /> }
                  color = "secondary"
                  onClick = { () => getCveData() }
                  className = { classes.button }
                  disabled={!cve}
                >
                  Fill with NVD Details
                </Button >
                :
                <div className={classes.custom__box}></div>
              }

            </div >
            <div className = { classes.block__item } >
              <TextInput
                label = "Vendor"
                source = "vendor"
                variant = "outlined"
                className = { classes.item }
                validate = { [required()] }
              />
              <TextInput
                label = "Product"
                source = "product"
                variant = "outlined"
                className = { classes.item }
                validate = { [required()] }
              />
            </div >
          </div >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
            <DateTimeInput
                label = "CVE Date"
                allowEmpty
                source = "cve_date"
                className = { classes.date }
                fullWidth = { true }
              />
            <DateTimeInput
                label = "Date Added"
                allowEmpty
                source = "date_added"
                className = { classes.date }
                fullWidth = { true }
              />
            </div >
            <div className = { classes.block__item } >
               <NumberInput
                  label = "Year"
                  allowEmpty
                  source = "year"
                  variant = "outlined"
                  className = { classes.item }
                />
                <TextInput
                  label = "CWE"
                  allowEmpty
                  source = "cwe"
                  variant = "outlined"
                  className = { classes.item }
                />
            </div >
          </div >
          <div className = { `${ classes.block } ${ classes.baseline }` } >
            <div className = { `${ classes.new_block__item } ${ classes.baseline }` } >
              <TextInput
                label = "External Note"
                allowEmpty
                multiline
                source = "external_note"
                variant = "outlined"                
                style = { {width: `${ windowWidth }`} }
                className = { classes.link }
              />
              <TextInput
                label = "Internal Note"
                allowEmpty
                multiline
                source = "internal_note"
                variant = "outlined"
                className = { classes.item }
              />
              </div >
            <div className = { `${ classes.new_block__item } ${ classes.baseline }` } >         
              <TextInput
                label = "Reference Link"
                allowEmpty
                source = "cve_reference"
                variant = "outlined"
                className = { classes.link }
              />
              <TextInput
                label = "Vulnerability Type"
                allowEmpty
                source = "vulnerability_type"
                variant = "outlined"
                className = { classes.item }  
              />
            </div >
          </div >
          <div className = { `${ classes.block__item } ${ classes.baseline }` } >
              <TextInput
                label = "Description"
                source = "description"
                multiline
                variant = "outlined"
                className = { classes.link }
              />
            </div >
          <div className = { classes.label__wrapper } >
                  <Typography className = { classes.label }
                              component = "h2"
                              style = { {
                                borderBottom: preferences === "dark" ? "2px solid rgba(81, 81, 81, 1)" : "2px solid rgba(224, 224, 224, 1)",
                              } }
                    >CTCI-Intel
                  </Typography >
            </div >
          <div className = { `${ classes.block } ${ classes.baseline }` } >
              <div className = { classes.block__item } >
                <DateTimeInput
                  label = "Honeypot"
                  allowEmpty
                  source = "honeypot"
                  helperText = "in GMT Time"
                  className = { classes.date }
                  fullWidth = { true }
                />
                <DateTimeInput
                label = "Intel"
                allowEmpty
                source = "intel"
                helperText = "in PST Time"
                className = { classes.date }
                fullWidth = { true }
                />
            </div >    
            <div className = { classes.block__item } >
              <DateTimeInput
                  label = "Research"
                  allowEmpty
                  source = "research"
                  helperText = "in PST Time"
                  className = { classes.date }
                  fullWidth = { true }
                />
            </div >
          </div >
          <div className = { classes.label__wrapper } >
                  <div className = { classes.label }
                              style = { {
                                borderBottom: preferences === "dark" ? "2px solid rgba(81, 81, 81, 1)" : "2px solid rgba(224, 224, 224, 1)",
                              } }
                    >
                  </div >
            </div >
        <div className = { classes.block } >
            <div className = { classes.block__item } >
              <BooleanInput
                  label = "notified "
                  source = "notified"
                  className = { classes.item }
              />
              <DateTimeInput
                label = "Notification Date"
                allowEmpty
                source = "notification_date"
                className = { classes.date }
                fullWidth = { true }
              />    
            <div className = { classes.block__item } >
              <BooleanInput
                label = "Enabled"
                source = "enabled"
                className = { classes.item }
              />
              <DateTimeInput
                label = "Enable from Date"
                allowEmpty
                source = "enable_from_date"
                className = { classes.date }
                fullWidth = { true }
              />  
           </div >
        </div >
      </div >
    <div className = { classes.block } >      
            <div className = { classes.block__item } >
              <BooleanInput
              label = "Removed"
              source = "removed"
              className = { classes.item }
              />
              <DateTimeInput
                label = "Removed Date"
                allowEmpty
                source = "removed_date"
                className = { classes.date }
                fullWidth = { true }
              />             
            </div >
            <div className = { classes.block__item } >
              <NumberInput
                label = "CVE Score"
                allowEmpty
                source = "base_score"
                variant = "outlined"
                className = { classes.item }
              />
              <div >
                <AutocompleteInput
                  label = "CVE Severity"
                  source = "base_severity"
                  emptyText = "UNKNOWN"
                  choices = { [
                        {id: "UNKNOWN", name: "Unknown"},
                        {id: "LOW", name: "Low"},
                        {id: "MEDIUM", name: "Medium"},
                        {id: "HIGH", name: "High"},
                        {id: "CRITICAL", name: "Critical"},
                        {id: "NUCLEAR", name: "Nuclear"},
                  ] }
                  variant = "outlined"
                  className = { classes.item }
                />
              </div >  
            </div >
            <div className = { classes.block__item }>
              <BooleanInput
                label = "CVSSv3"
                source = "base_metric_v3"
                className = { classes.item }
              />
              <BooleanInput
                label = "CVSSv2"
                source = "base_metric_v2"
                className = { classes.item }
              />
            </div>
            <div className = { classes.block__item } >
              <BooleanInput
               label = "Special"
               source = "special"
               className = { classes.item }
              />
            </div >
          </div >
        </div >
        <div className = { classes.right__block } >
          <div className = { classes.block } >
            <div className = { classes.block__item } >
              <NumberInput
                label = "Id Ref"
                allowEmpty
                source = "id_ref"
                disabled
                variant = "outlined"
                className = { classes.item }
              />
            </div>
            <div className={classes.custom__box}></div>
          </div>
        </div>
      </SimpleForm >
    </Create >
  );
};

export default CewlCreate;
