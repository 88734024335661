import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import {
    Admin,
    Layout,
    Resource,
    fetchUtils,
    defaultTheme,
    usePermissions,
} from "react-admin";
import merge from "lodash/merge";
import authProvider from "./provider/authProvider";
import DataProvider from "./provider/dataProvider";
import MyAppBar from "./MyAppBar";

import User from "./user";
import matchyCewl from "./matchymatchy/index"
import Company from "./company";
import EmailDomain from "./email_domain";
import NotificationGroup from "./notification_group";
import NotificationDelivery from "./notification_delivery";
import NotificationFilters from "./notification_filter";
import Token from "./token";
import Cewl from "./cewl";
import Ioc from "./ioc";
import DealRegistration from "./deal_registration";
import Processed from "./processed";
import Notification from "./notification";
import Dashboard from "./dashboard";
// import Error from "./layout/Notification";

import blue from "@material-ui/core/colors/blue";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import MyCoolLoginPage from "./auth/MyCoolLoginPage";
import { PreferencesBasedThemeProvider } from "@react-admin/ra-preferences";
import { LicenseManager } from "ag-grid-enterprise";
import { agGridLicenseKey } from "./agGridLicenseKey";

// For multi-level menu
import {
    AppLocationContext,
    MenuItem,
    MultiLevelMenu,
} from "@react-admin/ra-navigation";
import customRoutes from "./customRoutes";
import BusinessIcon from "@material-ui/icons/Business";
import UserIcon from "@material-ui/icons/Group";
import EmailIcon from "@material-ui/icons/Email";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import HttpsIcon from "@material-ui/icons/Https";
import TimelineIcon from "@material-ui/icons/Timeline";
import ViewListIcon from "@material-ui/icons/ViewList";

import ReceiptIcon from "@material-ui/icons/Receipt";

// turn off translations: https://stackoverflow.com/questions/51444875/turn-off-translations-for-react-admin
import polyglotI18nProvider from "ra-i18n-polyglot"; // install the package
import englishMessages from "ra-language-english"; // install the package

// Grab the configuration endpoints
import myEndpoints from "./config";
import ProgressBar from "./components/ProgressBar";


LicenseManager.setLicenseKey(agGridLicenseKey);

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const token = localStorage.getItem("access_token");
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options).catch((error) => {
        throw error;
    });
};

const themeFromType = (type) =>
    type === "light"
        ? merge({}, defaultTheme, {
            palette: {
                type: "light", // Switching the dark mode on is a single property value change.
                primary: blue,
                secondary: {
                    light: "#6ec6ff",
                    main: "#FFFFFF",
                    dark: "#0069c0",
                    contrastText: "#000",
                },
            },
            overrides: {
                RaMenuItem: {
                    gutters: {
                        padding: "4px 16px",
                    },
                },
                MuiFilledInput: {
                    root: {
                        borderRadius: "4px",
                        position: "relative",
                    },
                },
            },
            components: {
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            justifyContent: "flex-start",
                        },
                    },
                },
            },
        })
        : merge({}, defaultTheme, {
            palette: {
                type: "dark", // Switching the dark mode on is a single property value change.
                primary: blue,
                secondary: {
                    light: "#6ec6ff",
                    main: "#7F7F7F",
                    dark: "#0069c0",
                    contrastText: "#000",
                },
            },
            overrides: {
                RaMenuItem: {
                    gutters: {
                        padding: "4px 16px",
                    },
                },RaMenuItem: {
                  root: {
                      color: 'white',
                  },
                  link: {
                      '&:hover': {
                          color: 'white',
                          backgroundColor:"#424242",
                      },
                  },
              }
            },
        });

const MyMenu = () => {
    const [userRole, setUserRole] = useState();
    const {permissions} = usePermissions();

    useEffect(() => {
        permissions && permissions.rls && setUserRole(permissions.rls);
        return ()=> {
          setUserRole(" ");
        }
    }, [permissions]);
  return (
    <MultiLevelMenu>
      <MenuItem
        name="Documentation"
        to="/"
        exact
        label="Documentation"
        icon={<MenuBookRoundedIcon fontSize="small" />}
      />
      {userRole !== "web" && (
       <><MenuItem
          name="cewl"
          to="/cewl"
          label="CEWL"
          icon={<ViewListIcon fontSize="small" />} />
          <MenuItem
            name="CewlMatchy"
            to="/CewlMatchy"
            label="Matchy Matchy"
            icon={<ReceiptIcon fontSize="small" />} /></> 
      )}
      
      <MenuItem
        name="ip"
        to="/ip"
        label="Legit"
        icon={<TimelineIcon fontSize="small" />}
      />
      {userRole !== "web" && (
        <MenuItem
          name="admin"
          to={"."}
          label="Admin"
          icon={<AccountBoxIcon fontSize="small" />}
        >
          <MenuItem
            name="admin.users"
            to={"/user"}
            label="Users"
            icon={<UserIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.company"
            to={"/company"}
            label="Company"
            icon={<BusinessIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.email_domain"
            to={"/email_domain"}
            label="Email Domain"
            icon={<EmailIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.notification_group"
            to={"/notification_group"}
            label="Notification Groups"
            icon={<NotificationsIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.notification_delivery"
            to={"/notification_delivery"}
            label="Delivery"
            icon={<NotificationsActiveIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.notification_filters"
            to={"/notification_filter"}
            label="Notification Filters"
            icon={<AddAlertIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.processed"
            to={"/processed"}
            label="Processed"
            icon={<CheckBoxIcon fontSize="small" />}
          />
          <MenuItem
            name="admin.notification"
            to={"/notification"}
            label="Notification id_ref"
            icon={<ArrowRightAltIcon fontSize="small" />}
          />

                    <MenuItem
                        name = "admin.tokens"
                        to = {"/token"}
                        label = "Tokens"
                        icon = {<HttpsIcon fontSize = "small" />}
                    />

                    {userRole === "distributor_admin" ||
                    userRole === "super_admin" ||
                    userRole === "oem_admin" ||
                    userRole === "reseller_admin" ? (
                        <MenuItem
                            name = "admin.deal_registration"
                            to = {"/deal_registration"}
                            label = "Deals"
                            icon = {<ReceiptIcon fontSize = "small" />}
                        />
                    ) : null}
                </MenuItem >
            )}
        </MultiLevelMenu >
    );
};

const MyLayout = (props) => {
    return (
        <AppLocationContext >
            <Layout {...props} appBar = {MyAppBar} menu = {MyMenu}>
                <React.Suspense fallback = {<ProgressBar />} >
                    {props.children}
                </React.Suspense>
            </Layout>
            {/* <Error /> */}
        </AppLocationContext >
    );
};

const App = () => {
    useEffect(() => {
        document.title = "CTCI Portal";
    }, []);
    // a combination of two articles:  https://github.com/marmelab/react-admin/issues/5727 and one mentioned earlier
    const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
        allowMissing: true,
        onMissingKey: (key, _, __) => key,
    });

  return (
    <PreferencesBasedThemeProvider themeFromType={themeFromType}>
      <Admin
        title=""
        catchAll={() => <Redirect to="/" />}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        loginPage={MyCoolLoginPage}
        layout={MyLayout}
        disableTelemetry
        authProvider={authProvider}
        dataProvider={DataProvider(
          myEndpoints().REACT_APP_CRUD_ADMIN_ENDPOINT,
          httpClient
        )}
        i18nProvider={i18nProvider}
      >
        <Resource name="cewlMatchy" {...matchyCewl}  options={{ label: "CewlMatchy" }} />
        <Resource name="cewl" {...Cewl} options={{ label: "CEWL" }} />
        <Resource name="ip" {...Ioc} options={{ label: "Legit" }} />
        <Resource name="user" {...User} options={{ label: "Users" }} />
        <Resource
          name="company"
          {...Company}
          options={{ label: "Companies" }}
        />
        <Resource
          name="email_domain"
          {...EmailDomain}
          options={{ label: "Company Email Domains" }}
        />
        <Resource
          name="notification_group"
          {...NotificationGroup}
          options={{ label: "Notification Groups" }}
        />
        <Resource
          name="notification_delivery"
          {...NotificationDelivery}
          options={{ label: "Notification Deliveries" }}
        />
        <Resource
          name="notification_filter"
          {...NotificationFilters}
          options={{ label: "Notification Filters" }}
        />
        <Resource name="token" {...Token} options={{ label: "Tokens" }} />
        <Resource
          name="deal_registration"
          {...DealRegistration}
          options={{ label: "Deals" }}
        />
        <Resource
          name="processed"
          {...Processed}
          options={{ label: "Processed" }}
        />
        <Resource
          name="notification"
          {...Notification}
          options={{ label: "Notification id_ref" }}
        />
      </Admin>
    </PreferencesBasedThemeProvider>
  );
};

export default App;
