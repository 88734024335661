import myEndpoints from "../config";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: ({ username, password }) => {
    console.log("Checking endpoint")
    console.log(myEndpoints().REACT_APP_CRUD_ADMIN_LOGIN_ENDPOINT)
    const request = new Request(
      myEndpoints().REACT_APP_CRUD_ADMIN_LOGIN_ENDPOINT,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then(async (response) => {
        const json = await response.json();
        if (response.status < 200 || response.status >= 300) {
          if (json.parameter.redirect_url) {
            window.location.href = json.parameter.redirect_url;
          }
          if (json.parameter.password_change_url) {
            const link = json.parameter.password_change_url;
            localStorage.setItem("change_password", link);
            window.location.href += "/change_password";
          }
          throw new Error(json.detail);
        }
        return json;
      })
      .then((auth) => {
        //localStorage.setItem('access_token', JSON.stringify(auth.access_token));
        localStorage.setItem("access_token", auth.access_token);
        localStorage.setItem("firstname", auth.firstname);
        localStorage.setItem("lastname", auth.lastname);
        localStorage.setItem("nickname", auth.nickname);
        localStorage.setItem("roles", auth.roles);
      });
  },

  logout: () => {
    // username is removed from the checked button
    localStorage.removeItem("access_token");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("nickname");
    localStorage.removeItem("roles");
    localStorage.removeItem("user_id");
    return Promise.resolve("/login");
  },

  checkAuth: () => {
    return localStorage.getItem("access_token")
      ? Promise.resolve()
      : Promise.reject({ message: false });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    // roles are stored in the JWT in field rls
    let access_token_exist = localStorage.getItem("access_token", null);
    let role = "";
    if (access_token_exist) {
      try {
        role = decodeJwt(access_token_exist);
        // valid token format
      } catch (error) {
        // if there is a problem with the decode JWT then remove it
        localStorage.removeItem("access_token");
      }
    } else {
      const role = { rls: [] };
      console.log(role);
    }
    return role.rls ? Promise.resolve(role) : Promise.reject();
  },

  // my addition
  getIdentity: () => {
    try {
      let fullName = localStorage.getItem("nickname", "");
      if (fullName === undefined || fullName === null || fullName === "null") {
        fullName =
          localStorage.getItem("firstname") +
          " " +
          localStorage.getItem("lastname");
      }
      let access_token_exist = localStorage.getItem("access_token", null);

      if (access_token_exist) {
        try {
          const id = decodeJwt(access_token_exist);
          const user_id = id.id;
          // this line is not needed later on!
          if (user_id) {
            localStorage.setItem("user_id", user_id);
          }
          // look at adding avatars from local storage in the futrue
          const avatar = "";
          return Promise.resolve({ user_id, fullName, avatar });
          // valid token format
        } catch (error) {
          // if there is a problem with the decode JWT then remove it
          localStorage.removeItem("access_token");
          return Promise.reject(error);
        }
      } else {
        Promise.reject("access_token invalid");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
