import React, { useState, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Title,
  usePermissions,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { Link, useHistory } from "react-router-dom";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

import "../cewl.css";
import moment from "moment-timezone";
import jstz from "jstz";

// for search input
import SearchBar from "material-ui-search-bar";
import Grid from "@material-ui/core/Grid";

// to use react-admin's fetch
// We should use their fetchUtils!
// TODO use this library!
// import { fetchUtils } from "react-admin";

// for filter search
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ListAdd from "@material-ui/icons/PlaylistAdd";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { usePreferences } from "@react-admin/ra-preferences";
import CustomTooltip from "../components/CustomTooltip";

import { Box, Button, CircularProgress } from "@material-ui/core";

import myEndpoints from "../config.js";

import { makeStyles } from "@material-ui/core";

import copy from "copy-to-clipboard";

const useStyles = makeStyles({
  header: {
    fontFamily: '"data70"',
  },
  root: {
    fontFamily: '"data70"',
    fontSize: "30px",
    color: "#33cc99",
  },
  search_input: {
    width: "10ch",
    "& > label": {
      /* bring your own prefixes for webkit */
      "-webkit-transform": "translate(10px, 10px) scale(1)",
      transform: "translate(10px, 10px) scale(1)",
    },
    "& > div > input": {
      padding: "8.5px 30px 8.5px 8px",
    },
  },
  search_button: {
    color: "#2196f3",
    minWidth: "35px",
    padding: 0,
    border: "none",
    position: "absolute",
    right: 0,
    height: "35px",
    "& > .MuiButton-root": {
      padding: 0,
    },
    "& > span": {
      fontSize: "10px",
    },
  },
});

// Defining the CEWL header title
const CEWLHeader = ( ) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} ml={16}>
      <span>CEWL</span>
    </Box>
  );
};

// https://medium.com/javascript-in-plain-english/how-to-check-for-null-in-javascript-dffab64d8ed5
// typeof maybeUndeclared !== "undefined" && (typeof maybeUndeclared !== "object" || !maybeUndeclared)
const timestamp_to_string = (data) =>
  data.value &&
  moment.tz(data.value, "America/Los_Angeles").format("YYYY-MM-DD");

const show_truncated = (params) =>
  params.value &&
  `<span title="${params.value}">${params.value}</span>`;

const redirectEdit = (history) => {
  const selectedRow = gridOptions.api.getSelectedNodes();
  selectedRow.length && history.push(`cewl/${selectedRow[0].data.id}`);
};

const redirectShow = (history) => {
  const selectedRow = gridOptions.api.getSelectedNodes();
  selectedRow.length && history.push(`cewl/${selectedRow[0].data.id}/show`);
};

const tooltipValueGetter = (params) => {
  return params.value;
};

const columnDefs = [
  {
    headerName: "CVE",
    field: "id",
    headerTooltip: "CVE",
    width: 190,
    aggFunc: "count",
    checkboxSelection: (params) => {
      if (
        params.column.columnApi.isPivotMode() ||
        params.column.columnApi.getRowGroupColumns().length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    headerName: "External Note",
    field: "external_note",
    headerTooltip: "external_note",
    cellRenderer: show_truncated,
    enablePivot: false,
  },
  {
    headerName: "Year",
    field: "year",
    headerTooltip: "year",
    width: 85,
  },
  {
    headerName: "NVD Score",
    field: "base_score",
    headerTooltip: "base_score",
    width: 123,
    enablePivot: true,
  },
  {
    headerName: "CVE Date",
    field: "cve_date",
    headerTooltip: "cve_date",
    valueFormatter: timestamp_to_string,
    width: 123,
    enablePivot: true,
  },
  {
    headerName: "NVD Severity",
    field: "base_severity",
    headerTooltip: "base_severity",
    width: 123,
    enablePivot: true,
  },
  {
    headerName: "Vendor",
    field: "vendor",
    headerTooltip: "vendor",
    width: 150,
    enablePivot: true,
  },
  {
    headerName: "Product",
    field: "product",
    headerTooltip: "product",
    width: 270,
    enablePivot: true,
  },
  {
    headerName: "Description",
    field: "description",
    headerTooltip: "description",
    width: 270,
    cellRenderer: show_truncated,
    chartDataType: "excluded",
  },
  {
    headerName: "Date Added",
    field: "date_added",
    headerTooltip: "date_added",
    width: 130,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "Reference",
    field: "cve_reference",
    headerTooltip: "cve_reference",
    chartDataType: "excluded",
    tooltipComponent: "customTooltip",
    tooltipValueGetter: tooltipValueGetter,
  },
  {
    headerName: "Honeypot",
    field: "honeypot",
    headerTooltip: "honeypot",
    width: 150,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "Intel",
    field: "intel",
    headerTooltip: "intel",
    width: 150,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "Research",
    field: "research",
    headerTooltip: "research",
    width: 150,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "CVSS V2",
    field: "base_metric_v2",
    headerTooltip: "base_metric_v2",
    width: 129,
  },
  {
    headerName: "CVSS V3",
    field: "base_metric_v3",
    headerTooltip: "base_metric_v3",
    width: 129,
  },
  {
    headerName: "id_ref",
    field: "id_ref",
    headerTooltip: "id_ref",
    width: 129,
  },
  {
    headerName: "Updated On",
    field: "updated_on",
    headerTooltip: "updated_on",
    width: 129,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "CWE",
    field: "cwe",
    headerTooltip: "cwe",
    enablePivot: true,
  },

  {
    headerName: "Internal Note",
    field: "internal_note ",
    hide: true,
    headerTooltip: "internal_note",
    enablePivot: false,
  },
  {
    headerName: "Removed",
    field: "removed",
    hide: true,
    headerTooltip: "removed",
    enablePivot: true,
  },
  {
    headerName: "Removed Date",
    field: "removed_date",
    hide: true,
    headerTooltip: "removed_date",
    enablePivot: true,
  },
  {
    headerName: "Enabled",
    field: "enabled",
    headerTooltip: "enabled",
    enablePivot: true,
  },
  {
    headerName: "Processed",
    field: "processed",
    headerTooltip: "processed",
    enablePivot: true,
  },
  {
    headerName: "Vulnerability Type",
    field: "vulnerability_type",
    headerTooltip: "vulnerability_type",
    width: 270,
    enablePivot: true,
  },
  {
    headerName: "Notification Date",
    field: "notification_date",
    headerTooltip: "notification_date",
    width: 166,
    valueFormatter: timestamp_to_string,
    chartDataType: "time",
  },
  {
    headerName: "Notified",
    field: "notified",
  },
];

const subMenuItems = (params) => {
  const column = params.column.getId();
  const menu = params.defaultItems.slice(0);
  menu.push({
    name: "Select column",
    action: () => {
      gridOptions.api.addCellRange({
        rowStartPinned: "top",
        rowEndPinned: "bottom",
        columnStart: column,
        columnEnd: column,
      });
    },
  });
  return menu;
};
//Not the best way to change ag-grid error message, find the way how to make better
let errorMessage = "";
const CustomNoRowsOverlay = (props) => {
  return props.noRowsMessageFunc();
};

let color = "inherit";

const gridOptions = {
  columnDefs: columnDefs,
  columnTypes: {
    nonEditableColumn: { editable: false },
    dateColumn: {
      filter: "agDateColumnFilter",
      suppressMenu: true,
    },
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    width: 150,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
  },
  getMainMenuItems: subMenuItems,
  // toolPanels: [
  //   {
  //     id: "filters",
  //     labelDefault: "Filters",
  //     labelKey: "filters",
  //     iconKey: "filter",
  //     toolPanel: "agFiltersToolPanel",
  //     toolPanelParams: {
  //       suppressExpandAll: true,
  //       suppressFilterSearch: true,
  //     },
  //   },
  // ],
  getRowStyle: () => {
    return {
      color: color,
    };
  },
  // defaultToolPanel: "filters",
  enableRangeSelection: true,
  sideBar: {
    toolPanels: ["columns"],
  },
  enableCharts: true,
  rowGroupPanelShow: "always",
  pivotPanelShow: "always",
  statusBar: {
    statusPanels: [
      { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" },
    ],
  },
  frameworkComponents: {
    customNoRowsOverlay: CustomNoRowsOverlay,
    customTooltip: CustomTooltip,
  },
  noRowsOverlayComponent: "customNoRowsOverlay",
  noRowsOverlayComponentParams: {
    noRowsMessageFunc: () => {
      return errorMessage;
    },
  },
  overlayLoadingTemplate: `<span>Please wait, loading data…</span>`,
  tooltip: { class: 'my-tooltip' },
};

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

const CewlList = (props) => {
  const [searchBar, setSearchBar] = useState("");
  const [rowData, setRowData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [gridTheme, setGridTheme] = useState("ag-theme-alpine");
  const [error, setError] = useState(false);
  const [userRole, setUserRole] = useState();
  const [processedCounter, setProcessedCounter] = useState(1);
  const [timeFilter, setTimeFilter] = useState("");
  const [timeSearch, setTimeSearch] = useState("");
  const [time, setTime] = useState("all");

  const [preferences] = usePreferences("theme");
  const { permissions } = usePermissions();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const localUserTimezone = jstz.determine().name();
  const classes = useStyles();

  // for redirect and notification on login error
  const notify = useNotify();
  const redirect = useRedirect();

  // this is code for checking if we have mounted
  // TODO put this in it's own function
  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return isMountRef.current;
  };

  const isMount = useIsMount();

  useEffect(() => {
    timeSearch && setTime("none");
  }, [timeSearch]);

  useEffect(() => {
    // We only want to use the time filter after we have already loaded
    // this is due to the URL can be used to cut and paste and search
    // otherwise we have two search competing on the first render and race condition of who wins
    if (isMount) {
    } else {
      doSomethingWith(`${searchBar}${timeFilter}`);
    }
  }, [timeFilter]);

  //Not the best way to change ag-grid error message, find the way how to make better
  useEffect(() => {
    if (!showData && !error && gridOptions.api) {
      gridOptions.api.hideOverlay();
    }
    if (error && gridOptions.api) {
      errorMessage = error.detail;
      gridOptions.api.showNoRowsOverlay();
    }
  }, [error, showData]);

  useEffect(() => {
    permissions && permissions.rls && setUserRole(permissions.rls);
  }, [permissions]);

  const doSomethingWith = (q_value) => {
    color = "inherit";
    setProcessedCounter(1);
    
    // Sub filter code(Find filter)
    // If timeSearch is selected and some text is there in search bar 
    if (timeSearch && !q_value.includes('earliest'))
      q_value = q_value + `**|tz=${localUserTimezone}|earliest=-${timeSearch}|**`;
    // If timeSearch is selected and q_value already contains earliest so done to make querry consistent
    else if (timeSearch && q_value.includes('earliest'))
          q_value = searchBar + `**|tz=${localUserTimezone}|earliest=-${timeSearch}|**`;
    
    //Main filter code(Toggle button filters)
    // If timeFilter is selected and some text is there in search bar       
    if(timeFilter!=q_value && !q_value.includes(timeFilter) )
    {
      q_value=q_value+timeFilter;
    }
    // If same timeFilter is selected 
    else if(timeFilter===q_value)
    { 
      q_value=timeFilter;
    }
    
    const access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers({ Authorization: "Bearer " + access_token });
    try {
      fetch(myEndpoints().REACT_APP_CRUD_CEWL_ENDPOINT + q_value, {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            (data.status && data.status !== 200) ||
            (data.status_code && data.status_code !== 200)
          ) {
            if (data.status_code && data.status_code === 401) {
              // we need to log back in
              notify("Logged in token no longer valid", "Error");
              redirect("/login");
            }
            setError(data);
            setRowData([]);
          } else {
            setError(false);
            setRowData(data["data"]);
            setShowData(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      console.log("Caught the err");
    }
  };

  useEffect(() => {
    if (window.location.href.indexOf("q=") >= 0) {
      const urlSearch = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf("q=") + 2)
      );
      setRowData([]);
      if(urlSearch !== ""){
        setSearchBar(urlSearch);
        doSomethingWith(urlSearch);
      } else {
        setSearchBar("");
      }
    } else {
      setSearchBar("");
    }
  }, []);

  useEffect(() => {
    preferences === "light"
      ? setGridTheme("ag-theme-alpine")
      : setGridTheme("ag-theme-alpine-dark");
  }, [preferences]);

  const onNotificationBarChange = (event, values) => {
    if (values !== undefined && values !== null) {
      if (values.hasOwnProperty("filter")) {
        if (values.filter !== undefined && values.filter !== null) {
          setSearchBar(values.filter);
          doSomethingWith(values.filter);
          history.push(`${props.basePath}?q=${values.filter}`);
        }
      }
    }
  };

  const handleTimeChange = (event, newTime) => {
    setTime(newTime);
  };

  const getRowStyle = () => {
    const rows = [];
    gridOptions.api.forEachNode((node) => {
      if (node.data.processed) {
        rows.push(node);
        color = "#d6d6d6";
      }
    });
    gridOptions.api.redrawRows({ rowNodes: rows });
  };

  const updateProcessed = () => {
    const selectedRow = gridOptions.api.getSelectedNodes();
    if (selectedRow.length) {
      dataProvider
        .create("processed", {
          data: { cve_id: selectedRow[0].data.id },
        })
        .then(() => doSomethingWith(searchBar))
        .catch((error) => console.log(error));
    }
  };

  const toggleProcessed = () => {
    gridOptions.api.setFilterModel(null);
    switch (processedCounter) {
      case 1: {
        gridOptions.api.setFilterModel(null);
        getRowStyle();
        setProcessedCounter((prev) => prev + 1);
        break;
      }
      case 2: {
        const filter = {
          processed: {
            type: "set",
            values: ["false"],
          },
        };
        gridOptions.api.setFilterModel(filter);
        color = "inherit";
        setProcessedCounter((prev) => prev + 1);
        break;
      }
      case 3: {
        const filter = {
          processed: {
            type: "set",
            values: ["true", "false"],
          },
        };
        gridOptions.api.setFilterModel(filter);
        color = "inherit";
        setProcessedCounter(1);
        break;
      }
      default:
        break;
    }
  };


  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  const AsyncAutocomplete = () => {
    const [open, setOpen] = React.useState(false);
    const loading = open && notifications.length === 0;

    useEffect(() => {
      let active = true;

      if (!loading) {
        return undefined;
      }

      (async () => {
        const access_token = localStorage.getItem("access_token");
        const myHeaders = new Headers({ Authorization: "Bearer " + access_token });
        await fetch(myEndpoints().REACT_APP_CRUD_ADMIN_ENDPOINT + "/notification_filter", {
          method: "GET",
          headers: myHeaders,
          mode: "cors",
        })
            .then((result) => result.json())
            .then(({ data }) => {
              if (
                (data.status && data.status !== 200) ||
                (data.status_code && data.status_code !== 200)
              ) {
                  const filters = [{
                    title: "Something went wrong",
                    id: 401,
                    filter: null,
                  }];
                  setNotifications(filters);
                  notify("Notification Filter: "+ data.detail,"error");
              } else if (data.length === 0 && active) {
                const filters = [{
                  title: "No data found",
                  id: 402,
                  filter: null,
                }];
                setNotifications(filters);
                notify("Notification Filter: No data found","error");
              } else if (data && active) {
                const filters = data.map((item) => ({
                  title: item.filter_name,
                  id: item.id,
                  filter: item.filter,
                }));
                setNotifications(filters);
              }
            });
      })();

      return () => {
        active = false;
      };
    }, [loading]);

    return (
        <Autocomplete
            id="asynchronous-id"
            style={{ width: 300 }}
            size="small"
            open={open}
            disabled={!notifications}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            getOptionDisabled={(option) =>
              {
                if(option.id === 401 || option.id === 402){
                  return true
                }               
              }              
            }
            onChange={onNotificationBarChange}
            getOptionLabel={(option) => option.title}
            options={notifications}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Notification Filter"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                      ),
                    }}
                />
            )}
        />
    );
  }

  return (
    <>
      <div>
        <Title title={<CEWLHeader />} />
        {userRole !== "web" && ( AsyncAutocomplete() )}
        <br />
        <Grid container justifyContent="space-between" wrap="wrap">
          <Grid item xs={12} lg={6}>
            <SearchBar
              placeholder={"Type in search, hit enter for full CEWL List"}
              onChange={(newValue) => setSearchBar(newValue)}
              onRequestSearch={() => {
                setSearchBar(searchBar);
                doSomethingWith(searchBar);
                history.push(`${props.basePath}?q=${searchBar}`);
              }}
              onCancelSearch={() => {
                doSomethingWith(timeFilter);
                setSearchBar("");
                history.push(props.basePath);
              }}
              value={searchBar}
              autoFocus
              multiline
              onKeyDown={handleKeyDown}
              style={{
                margin: 0,
                maxWidth: 800,
                height: "auto",
                fontFamily: "Arial",
                fontSize: "16px",
                border: error && "2px solid red",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {userRole !== "web" && (
                <>
                  <Tooltip title="Add Notification Filter">
                    <label htmlFor="icon-add-notification-filter">
                      <IconButton
                        component={Link}
                        to={{
                          pathname: `/notification_filter/create/`,
                          search:`?notFilter=${searchBar}`,
                        }}
                        color="primary"
                        aria-label="add_notification_filter"
                      >
                        <AddAlertIcon />
                      </IconButton>
                    </label>
                  </Tooltip>
                  <Tooltip title="Processed">
                    <label htmlFor="icon-processed">
                      <IconButton
                        onClick={() => updateProcessed()}
                        color="primary"
                        aria-label="add_notification_filter"
                      >
                        <CheckBoxIcon />
                      </IconButton>
                    </label>
                  </Tooltip>
                </>
              )}
              <form
                style={{
                  position: "relative",
                  display: "inline-flex",
                  marginRight: "10px",
                  marginLeft: userRole === "web" ? "10px" : 0,
                }}
              >
                <TextField
                  label="Find"
                  variant="outlined"
                  className={classes.search_input}
                  onChange={(e) => setTimeSearch(e.target.value)}
                />
                <Button
                  onClick={() =>
                    doSomethingWith(
                      `${searchBar}**|tz=${localUserTimezone}|earliest=-${timeSearch}|**`
                    )
                  }
                  variant="outlined"
                  className={classes.search_button}
                >
                  Ago
                </Button>
              </form>
              <ToggleButtonGroup
                size="small"
                value={time}
                exclusive
                onChange={handleTimeChange}
              >
                <ToggleButton
                  style={{ textTransform: "none" }}
                  value="all"
                  onClick={() =>
                    setTimeFilter(`**|tz=${localUserTimezone}|earliest=all|**`)
                  }
                >
                  All
                </ToggleButton>
                <ToggleButton
                  style={{ textTransform: "none" }}
                  value="twentyfour"
                  onClick={() =>
                    setTimeFilter(`**|tz=${localUserTimezone}|earliest=-24h|**`)
                  }
                >
                  1d
                </ToggleButton>
                <ToggleButton
                  style={{ textTransform: "none" }}
                  value="twodays"
                  onClick={() =>
                    setTimeFilter(`**|tz=${localUserTimezone}|earliest=-2d|**`)
                  }
                >
                  2d
                </ToggleButton>
                <ToggleButton
                  style={{ textTransform: "none" }}
                  value="week"
                  onClick={() =>
                    setTimeFilter(`**|tz=${localUserTimezone}|earliest=-1w|**`)
                  }
                >
                  1w
                </ToggleButton>
                <ToggleButton
                  style={{ textTransform: "none" }}
                  value="month"
                  onClick={() =>
                    setTimeFilter(`**|tz=${localUserTimezone}|earliest=-1m|**`)
                  }
                >
                  1m
                </ToggleButton>
              </ToggleButtonGroup>
              {userRole !== "web" && (
                <Tooltip title="Show/Hide/Gray Processed">
                  <label htmlFor="icon-show-processed">
                    <IconButton
                      color="primary"
                      aria-label="show_processed"
                      component="span"
                      onClick={() => toggleProcessed()}
                    >
                      {processedCounter === 2 && <FormatPaintIcon />}
                      {processedCounter === 3 && <VisibilityOffIcon />}
                      {processedCounter === 1 && <VisibilityIcon />}
                    </IconButton>
                  </label>
                </Tooltip>
              )}
              <Tooltip title="Copy Full URL to Clipboard">
                <label htmlFor="icon-show-clipboard">
                  <IconButton
                    color="primary"
                    aria-label="copy_to_clipboard"
                    component="span"
                    onClick={() => {
                      console.log("copied to clipboard");
                      copy(window.location.origin + "/#/cewl?q=" + searchBar);
                    }}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </label>
              </Tooltip>
              {userRole === "super_admin" ? (
                <>
                  <Tooltip title="Cewl edit">
                    <label htmlFor="cewl-edit">
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        component="span"
                        onClick={() => {
                          redirectEdit(history);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </label>
                  </Tooltip>
                  <Tooltip title="Create">
                    <label htmlFor="cewl-create">
                      <IconButton
                        color="primary"
                        aria-label="cewl_create"
                        component="span"
                        onClick={() => {
                          history.push(`${props.basePath}/create`);
                        }}
                      >
                        <ListAdd />
                      </IconButton>
                    </label>
                  </Tooltip>{" "}
                </>
              )
              :   <Tooltip title="Cewl show">
                    <label htmlFor="cewl-show">
                      <IconButton
                          color="primary"
                          aria-label="show"
                          component="span"
                          onClick={() => {
                            redirectShow(history);
                          }}
                      >
                        <FindInPageIcon fontSize="small" />
                      </IconButton>
                    </label>
                  </Tooltip>
              }
            </Box>
          </Grid>
        </Grid>
        <br />
      </div>
      <div
        className={gridTheme}
        style={{
          flex: "1 1 0px",
          overflow: "hidden !important",
          display: "grid",
        }}
      >
        <AgGridReact
          gridOptions={gridOptions}
          rowData={rowData}
          checkboxSelection
          rowGroup
        >
          {columnDefs.map((column) => (
            <AgGridColumn {...column} key={column.headerName} sortable filter />
          ))}
        </AgGridReact>
      </div>
    </>
  );
};

export default CewlList;
